import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"

function ImageCarousel({ images }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <div>
      <Slider {...settings}>
        {images?.map((image, index) => {
          if (image.link !== null) {
            // Check if this is an internal link
            if (image.link?.startsWith("/")) {
              return (
                <div
                  key={index}
                  className="relative border-[#fd1c36] border-b-4"
                >
                  <Link href={image.link} rel="noreferrer">
                    <img src={image.src} alt={image.alt} className="w-full" />
                    <div className="absolute bottom-4 left-4 md:bottom-8 md:left-8">
                      {image.title && (
                        <div className="slide-title block py-1 px-3 border-none bg-white/90 text-lg mb-1 w-fit transition-all md:text-[26px] md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                          {image.title}
                        </div>
                      )}
                      {image.caption && (
                        <div className="slide-caption block py-1 px-3 border-none bg-white/90 text-xs w-fit md:text-base transition-all md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                          {image.caption}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  className="relative border-[#fd1c36] border-b-4"
                >
                  <a href={image.link} rel="noreferrer">
                    <img src={image.src} alt={image.alt} className="w-full" />
                    <div className="absolute bottom-4 left-4 md:bottom-8 md:left-8">
                      {image.title && (
                        <div className="slide-title block py-1 px-3 border-none bg-white/90 text-lg mb-1 w-fit transition-all md:text-[26px] md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                          {image.title}
                        </div>
                      )}
                      {image.caption && (
                        <div className="slide-caption block py-1 px-3 border-none bg-white/90 text-xs w-fit md:text-base transition-all md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                          {image.caption}
                        </div>
                      )}
                    </div>
                  </a>
                </div>
              )
            }
          } else {
            return (
              <div key={index} className="relative border-[#fd1c36] border-b-4">
                <img src={image.src} alt={image.alt} className="w-full" />
                <div className="absolute bottom-4 left-4 md:bottom-8 md:left-8">
                  {image.title && (
                    <div className="slide-title block py-1 px-3 border-none bg-white/90 text-lg mb-1 w-fit transition-all md:text-[26px] md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                      {image.title}
                    </div>
                  )}
                  {image.caption && (
                    <div className="slide-caption block py-1 px-3 border-none bg-white/90 text-xs w-fit md:text-base transition-all md:py-3 md:px-5 hover:bg-[#fd1c36] hover:text-white">
                      {image.caption}
                    </div>
                  )}
                </div>
              </div>
            )
          }
        })}
      </Slider>
      <div className="slick-slide slick-active hidden" />
    </div>
  )
}

export default ImageCarousel
