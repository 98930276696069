import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageCarousel from "../components/ImageCarousel"
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight"

const IndexPage = () => {
  const images = [
    {
      src: "/banners/fra-0028-banners-codeofpractice.jpg",
      alt: "Code of practice",
      title: "Code of practice",
      caption: "Setting the standard",
      link: "/code-of-practice/",
    },
    {
      src: "/banners/fra-0028-banners-ouragreement.jpg",
      alt: "Our agreement",
      title: "Our agreement",
      caption: "Knowing how it works",
      link: "/our-agreement/",
    },
    {
      src: "/banners/fra-0028-banners-pubstolet.jpg",
      alt: "Pubs to let",
      title: "Pubs to let",
      caption: "Find available businesses",
      link: "/pubs-to-let/",
    },
    {
      src: "/banners/fra-0028-banners-ourpartnership.jpg",
      alt: "Our partnership",
      title: "Our partnership",
      caption: "Working together effectively",
      link: "/our-partnership/",
    },
  ]

  return (
    <Layout>
      <SEO title="About Us" />

      <ImageCarousel images={images} />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full lg:w-3/5 p-4 border-r border-gray-300 pt-10 md:p-10">
          <h1>Welcome to Mitchells & Butlers Lease</h1>

          <p>
            Established in 1898, we're one of the largest operators of managed
            restaurants, pubs and bars in the UK, providing a wide choice of
            eating and drinking-out experiences through our well-known brands,
            such as Miller & Carter, Premium Country Pubs and Ember Inns. Whilst
            we finished brewing more than 20 years ago we now operate around
            1750 pubs and restaurants across the UK, and have specialised in
            developing high quality businesses delivering great service, quality
            and value for money to our guests. For more details, please visit
            our main website{" "}
            <a href="https://www.mbplc.com" target="_blank" rel="noreferrer">
              www.mbplc.com
            </a>
            .
          </p>

          <p>
            Since 2002 a small number of our managed pubs have been transferred
            to a lease arrangement and are now operated by self-employed
            entrepreneurs under the banner of Mitchells & Butlers Lease. Our 70+
            sites, with new opportunities being added, are found across the UK
            and consist of different types of business, from local community
            pubs to food led destination restaurants.
          </p>

          <p>
            For details on our current or future vacancies please{" "}
            <Link to="/contact-us/">contact us</Link> for more information and
            regular updates.
          </p>
        </div>

        <div className="w-full lg:w-2/5 p-4 md:p-10">
          <Link
            to="/contact-us/"
            className={`text-[#1d1d1d] flex items-center mb-8 font-regular transition-all hover:text-[#fd1c36]`}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-10 h-10 bg-[#fd1c36] text-white mr-4 border-[#fd1c36] border-4`}
            >
              <path d="M4 4h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 16h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4z"></path>
            </svg>
            <span
              className={`text-base py-3 flex items-center justify-between flex-1 border-b border-gray-300`}
            >
              Our Team
              <BsArrowRight className={`w-6 h-6`} />
            </span>
          </Link>

          <h3>Shaun Jackson</h3>
          <p>
            Responsible for Birmingham and all areas North including Scotland.
            Shaun has worked in the industry in a number of roles including more
            recently in Ei in North Wales and Cheshire. He started his Mitchells
            and Butlers career in March 2023.
          </p>

          <h3>Richard Flaxman</h3>
          <p>
            Responsible for all areas South of Birmingham including London &
            South East and South Wales. Richard has worked for Mitchells and
            Butlers for over 20 years, including more than 15 years in Lease.
            Prior to that he worked for Bass Brewers.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
